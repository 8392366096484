export const prefix = {
  annotation: 'https://meld.linkedmusic.org/annotations/',
  compVocab: 'https://meld.linkedmusic.org/companion/vocab/',
  mei: 'https://meld.linkedmusic.org/mei/',
  meldterm: 'https://meld.linkedmusic.org/terms/',
  workset: 'https://meld.linkedmusic.org/worksets/',
  rdf: 'http://www.w3.org/1999/02/22-rdf-syntax-ns#',
  popRoles: 'http://pop.linkedmusic.org/roles/',
  mo: 'http://purl.org/ontology/mo/',
  ldp: 'http://www.w3.org/ns/ldp#',
  mp: 'http://id.loc.gov/authorities/performanceMediums/',
  mps: 'https://id.loc.gov/authorities/performanceMediums/',
  oa: 'http://www.w3.org/ns/oa#',
  dct: 'http://purl.org/dc/terms/',
  frbr: 'http://purl.org/vocab/frbr/core#',
  rdfs: 'http://www.w3.org/2000/01/rdf-schema#',
  meld: 'https://meld.linkedmusic.org/terms/',
  motivation: 'https://meld.linkedmusic.org/motivation/',
  so: 'http://www.linkedmusic.org/ontologies/segment/',
  climb: 'http://meld.linkedmusic.org/climb/terms/',
  mc: 'http://meld.linkedmusic.org/climb/muzicodeTypes/',
  dbp: 'http://dbpedia.org/page/',
  bith: 'https://domestic-beethoven.eu/',
  bithTerms: 'https://domestic-beethoven.eu/Terms/',
  bibo: 'http://purl.org/ontology/bibo/',
  gndo: 'https://d-nb.info/standards/elementset/gnd#',
  dce: 'http://purl.org/dc/elements/1.1/',
  dbpedia: 'https://dbpedia.org/ontology/',
  rdau: 'http://rdaregistry.info/Elements/u/',
  wdt: 'https://www.wikidata.org/prop/direct/',
  foaf: 'http://xmlns.com/foaf/0.1/',
  iiif2: 'http://iiif.io/api/presentation/2#',
  sioc: 'http://rdfs.org/sioc/services#'
}
